import React from "react";
import PropTypes from "prop-types";
import Modal from "app/components/Modal";
import styled from "styled-components";
import Flex from "../../components/Flex";
import { H2 } from "../../components/Typography";
import {
  ModalBody,
  ModalHeader,
  ModalWrapper,
} from "../../components/Modal/components";
import Button from "../../components/Button";
import Icon from "../../components/Icon";

const ModalHero = styled(Flex)`
  place-items: center;
  height: 300px;
  max-height: 30vh;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.blue} 0%, ${theme.colors.cleanMint[4]} 200%)`};
  color: white;
  overflow: hidden;
  padding: 32px 0;
  box-sizing: border-box;
`;

const ActionModal = ({
  close,
  isOpen,
  ctaAction,
  ctaText,
  icon,
  titleText,
  descriptionLabel,
}) => {
  return (
    <Modal
      borderRadius="12px"
      maxWidth="400px"
      p={0}
      isOpen={isOpen}
      onBackgroundClick={close}
    >
      <ModalWrapper>
        <Flex flexDirection="column" height="100%">
          <ModalHeader minHeight="fit-content" p={3} toggleModal={close}>
            <H2 color="white">{titleText}</H2>
          </ModalHeader>
          <ModalHero>
            <Icon as={icon} width="100%" height="100%" mr={1} />
          </ModalHero>
          <ModalBody p={3}>
            {descriptionLabel}
            <Button mt={1} onClick={ctaAction}>
              {ctaText}
            </Button>
          </ModalBody>
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

ActionModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaAction: PropTypes.func.isRequired,
  descriptionLabel: PropTypes.element.isRequired,
  icon: PropTypes.elementType.isRequired,
  titleText: PropTypes.string.isRequired,
};

export default ActionModal;
