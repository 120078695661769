import CardList from "app/components/CardList";
import ClassCard from "app/components/ClassCard";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import {
  CenterWrapper,
  Container,
  PageTitle,
} from "app/components/RouteComponents";
import ScrollToTopOnMount from "app/components/ScrollToTopOnMount";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { useUnlockedClasses } from "./hooks";
import Flex from "../../components/Flex";
import Icon, { QuestionCircle, VideoLibrary } from "../../components/Icon";
import { H4, P1 } from "../../components/Typography";
import ActionModal from "./ActionModal";

const Title = styled(Flex)`
  justify-content: space-between;
`;

const Summary = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

const Unlocked = () => {
  const {
    unlockedClasses,
    hasUnavailableClasses,
    loading,
  } = useUnlockedClasses();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <CenterWrapper>
      <Helmet title="Unlocked Classes | Online Dance Classes and Tutorials" />
      <ActionModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        ctaAction={() => setIsModalOpen(false)}
        ctaText="Got it"
        titleText="Unlocked Classes"
        icon={VideoLibrary}
        descriptionLabel={
          <P1>
            Classes are updated periodically. If there are missing classes you
            have unlocked before,{" "}
            <a href="https://steezy.zendesk.com/hc/en-us">contact us</a> for
            help.
          </P1>
        }
      />
      <ScrollToTopOnMount />
      <Container>
        <Title>
          <PageTitle pb={[4]}>Unlocked classes</PageTitle>
          {hasUnavailableClasses && (
            <Icon
              width="auto"
              height="32px"
              color="blue"
              cursor="pointer"
              as={QuestionCircle}
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </Title>
        <Summary>
          <H4>Here&apos;s a list of classes you&apos;ve unlocked</H4>
        </Summary>
        {loading && <LoaderCentered />}
        <CardList
          mt={2}
          cards={unlockedClasses}
          renderCard={({ cardData }) => (
            <ClassCard
              key={cardData.id}
              data={cardData}
              selectedFrom="UnlockedClasses"
            />
          )}
        />
      </Container>
    </CenterWrapper>
  );
};

export default Unlocked;
